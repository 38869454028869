import React from "react";

import StaticLayout from "../components/StaticLayout";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-41"
        className="post-41 page type-page status-publish hentry"
      >
        <header className="entry-header">
          <h1 className="entry-title">Uurrooster</h1>{" "}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <h3>
            <strong>Donderdag:&nbsp; </strong>(Locatie: Eikenbos Boskestraat 3,
            Lummen)
          </h3>
          <h4 style={{ marginBottom: "24px" }}>
            Nieuwe lessenreeksen starten op 2 februari 2025.
          </h4>
          <p>18u00&nbsp;&nbsp; Solo Dans</p>
          <p>19u00&nbsp;&nbsp; Stijldansen 4</p>
          <p>20u00&nbsp;&nbsp; Disco-Boogie</p>
          <p>&nbsp;</p>
          <h3>
            <strong>Zondag: </strong>(Locatie: Casino, Varenstraat 22,
            Houthalen)
          </h3>
          <h4 style={{ marginBottom: "24px" }}>
            Nieuwe lessenreeksen starten op 6 februari 2025.
          </h4>
          <p>18u00&nbsp; &nbsp; Stijldansen 3</p>
          <p>19u00&nbsp; &nbsp; Start-to-dance (koppeldans beginners)</p>
          <p>20u00&nbsp; &nbsp; Stijldansen 2</p>
          <p>&nbsp;</p>
          <p>*in elke les is een kleine pauze voorzien</p>
          <p />
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1950 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1950,0" />
        </svg>
      </div>
    </main>
  </StaticLayout>
);

export default Page;
