import React from "react";
import { Link } from "gatsby";

import StaticLayout from "../components/StaticLayout";

const Home = () => (
  <StaticLayout>
    <main id="main" className="site-main" role="main">
      <div id="ob-grid" className="grid-layout">
        <article
          id="post-666"
          className="post-666 post type-post status-publish format-standard hentry category-geen-categorie"
        >
          <div className="svg-container post-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1950 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="M 0,150 0,0 1950,0" />
            </svg>
          </div>
          <div className="post-inner no-thumb">
            <header className="entry-header">
              <h2 className="entry-title">
                <Link to="/gratisproeflessen/" rel="bookmark">
                  Gratis Proeflessen
                </Link>
              </h2>
            </header>
            {/* .entry-header */}
            <div className="entry-content">
              <p>
                Ontdek jouw dansvibe tijdens onze gratis proeflessen! Op 2 en 6
                februari nodigen we je uit om vrijblijvend deel te nemen aan
                onze proeflessen. Kom langs en ontdek verschillende dansstijlen
                om te zien welke stijl jou het[...]
              </p>
            </div>
            {/* .entry-content */}
          </div>
          <Link to="/gratisproeflessen/">
            <div className="read-more">Ga verder met lezen … </div>
          </Link>
          <div className="svg-container post-bottom-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </article>
        <article
          id="post-336"
          className="post-336 post type-post status-publish format-standard hentry category-geen-categorie hidden"
        >
          <div className="svg-container post-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1950 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="M 0,150 0,0 1950,0" />
            </svg>
          </div>
          <div className="post-inner no-thumb">
            <header className="entry-header">
              <h2 className="entry-title">
                <Link to="/opendeurdag/" rel="bookmark">
                  Opendeurdag 15 september 2023 – 20u30
                </Link>
              </h2>
            </header>
            {/* .entry-header */}
            <div className="entry-content">
              <p>
                Wil je graag leren dansen? Blijf niet langer aan de kant zitten
                en grijp je kans. Naast stijldansen bieden we ook solodans,
                mambo, disco-boogie, Nightclub 2 step, … aan. Voor elk wat wils.
                Kom zeker[…]
              </p>
            </div>
            {/* .entry-content */}
          </div>
          <Link to="/opendeurdag/">
            <div className="read-more">Ga verder met lezen … </div>
          </Link>
          <div className="svg-container post-bottom-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </article>
        {/* #post-## */}
        <article
          id="post-15"
          className="post-15 post type-post status-publish format-standard hentry category-geen-categorie"
        >
          <div className="svg-container post-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1950 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="M 0,150 0,0 1950,0" />
            </svg>
          </div>
          <div className="post-inner no-thumb">
            <header className="entry-header">
              <h2 className="entry-title">
                <Link to="/start-to-dance/" rel="bookmark">
                  Start to dance
                </Link>
              </h2>
            </header>
            {/* .entry-header */}
            <div className="entry-content">
              <p>
                Heb jij zin om verschillende dansen te ontdekken en uit te
                proberen? Dan is onze cursus ‘Start To Dance’ helemaal iets voor
                jou! De cursus is op maat van beginnende dansers of mensen met
                weinig[…]
              </p>
            </div>
            {/* .entry-content */}
          </div>
          <Link to="/start-to-dance/">
            <div className="read-more">Ga verder met lezen … </div>
          </Link>
          <div className="svg-container post-bottom-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </article>
        {/* #post-## */}
        <article
          id="post-131"
          className="post-131 post type-post status-publish format-standard hentry category-geen-categorie hidden"
        >
          <div className="svg-container post-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1950 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="M 0,150 0,0 1950,0" />
            </svg>
          </div>
          <div className="post-inner no-thumb">
            <header className="entry-header">
              <h2 className="entry-title">
                <Link to="/nightclub-two-step/" rel="bookmark">
                  Nightclub Two Step
                </Link>
              </h2>
            </header>
            {/* .entry-header */}
            <div className="entry-content">
              <p>
                Nightclub Two-step is een vlotte, sociale dans die gedanst wordt
                op leuke, langzame muziek (Vb. Chris de Burgh – The Lady in Red,
                Ed Sheeran – Thinking out loud, …). Het is bedacht in 1960[…]
              </p>
            </div>
            {/* .entry-content */}
          </div>
          <Link to="/nightclub-two-step/">
            <div className="read-more">Ga verder met lezen … </div>
          </Link>
          <div className="svg-container post-bottom-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </article>
        {/* #post-## */}
        <article
          id="post-13"
          className="post-13 post type-post status-publish format-standard hentry category-geen-categorie"
        >
          <div className="svg-container post-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1950 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="M 0,150 0,0 1950,0" />
            </svg>
          </div>
          <div className="post-inner no-thumb">
            <header className="entry-header">
              <h2 className="entry-title">
                <Link to="/disco-boogie-swing/" rel="bookmark">
                  Disco-Boogie (Swing)
                </Link>
              </h2>
            </header>
            {/* .entry-header */}
            <div className="entry-content">
              <p>
                De disco-boogie wordt ook wel eens de discoswing genoemd. Deze
                dans is ideaal voor paren die graag uit de bol gaan op pop-of
                discomuziek. Het is een mix van elementen uit verschillende
                dansen. Zo kan[…]
              </p>
            </div>
            {/* .entry-content */}
          </div>
          <Link to="/disco-boogie-swing/">
            <div className="read-more">Ga verder met lezen … </div>
          </Link>
          <div className="svg-container post-bottom-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </article>
        <article
          id="post-667"
          className="post-667 post type-post status-publish format-standard hentry category-geen-categorie"
        >
          <div className="svg-container post-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1950 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="M 0,150 0,0 1950,0" />
            </svg>
          </div>
          <div className="post-inner no-thumb">
            <header className="entry-header">
              <h2 className="entry-title">
                <Link to="/solodans/" rel="bookmark">
                  Solodans
                </Link>
              </h2>
            </header>
            {/* .entry-header */}
            <div className="entry-content">
              <p>
                Ben je op zoek naar avontuur, maar ontbreekt het je aan een
                danspartner? Of misschien heb je een partner die liever op de
                zijlijn staat? Geen zorgen, want wij hebben dé oplossing voor
                jouw[...]
              </p>
            </div>
            {/* .entry-content */}
          </div>
          <Link to="/solodans/">
            <div className="read-more">Ga verder met lezen … </div>
          </Link>
          <div className="svg-container post-bottom-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </article>
        {/* #post-## */}
        <article
          id="post-9"
          className="post-9 post type-post status-publish format-standard hentry category-geen-categorie"
        >
          <div className="svg-container post-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1950 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="M 0,150 0,0 1950,0" />
            </svg>
          </div>
          <div className="post-inner no-thumb">
            <header className="entry-header">
              <h2 className="entry-title">
                <Link to="/privelessen/" rel="bookmark">
                  Privélessen
                </Link>
              </h2>
            </header>
            {/* .entry-header */}
            <div className="entry-content">
              <p>
                Bij Dance Square kan iedereen terecht voor privélessen, of je nu
                een recreatieve danser bent of een wedstrijddanser. Heb je geen
                tijd om elke week deel te nemen aan groepslessen, of wil je
                graag een[…]
              </p>
            </div>
            {/* .entry-content */}
          </div>
          <Link to="/privelessen/">
            <div className="read-more">Ga verder met lezen … </div>
          </Link>
          <div className="svg-container post-bottom-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </article>
        {/* #post-## */}
        <article
          id="post-5"
          className="post-5 post type-post status-publish format-standard hentry category-geen-categorie hidden"
        >
          <div className="svg-container post-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1950 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="M 0,150 0,0 1950,0" />
            </svg>
          </div>
          <div className="post-inner no-thumb">
            <header className="entry-header">
              <h2 className="entry-title">
                <Link to="/5/" rel="bookmark">
                  Salsa &amp; Bachata
                </Link>
              </h2>
            </header>
            {/* .entry-header */}
            <div className="entry-content">
              <p>
                Droom jij bij het horen van zuiderse muziek wel eens weg naar
                Midden-Amerika en wil je graag even goed kunnen dansen als de
                echte latino’s en latina’s? Dan zijn salsa en bachata zeker jouw
                ding.[…]
              </p>
            </div>
            {/* .entry-content */}
          </div>
          <Link to="/5/">
            <div className="read-more">Ga verder met lezen … </div>
          </Link>
          <div className="svg-container post-bottom-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </article>
        {/* #post-## */}
        <article
          id="post-11"
          className="post-11 post type-post status-publish format-standard hentry category-geen-categorie"
        >
          <div className="svg-container post-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1950 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="M 0,150 0,0 1950,0" />
            </svg>
          </div>
          <div className="post-inner no-thumb">
            <header className="entry-header">
              <h2 className="entry-title">
                <Link to="/openingsdans/" rel="bookmark">
                  Openingsdans
                </Link>
              </h2>
            </header>
            {/* .entry-header */}
            <div className="entry-content">
              <p>
                Proficiat! Jullie gaan trouwen! Bij de organisatie van een
                bruiloft komt heel wat kijken. Ook over de openingsdans breken
                veel koppels zich het hoofd. Maar maak je geen zorgen, bij ons
                kan je terecht voor[…]
              </p>
            </div>
            {/* .entry-content */}
          </div>
          <Link to="/openingsdans/">
            <div className="read-more">Ga verder met lezen … </div>
          </Link>
          <div className="svg-container post-bottom-svg svg-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1890 150"
            >
              <g transform="translate(0,-902.36218)" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
              <path d="m 1925,0 0,150 -1925,0" />
            </svg>
          </div>
        </article>
        {/* #post-## */}
      </div>
    </main>
  </StaticLayout>
);

export default Home;
